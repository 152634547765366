import React, { useState } from "react";
import {Card, Table, Tabs, Tab} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {properties} from "../properties";
import * as Auth from "../AuthService";
import Image from "react-bootstrap/Image";
import * as _ from "lodash";
import './QAManager.css';
import api from "../api";
import MetricsCard from "./MetricsCard";
import GeneralAttributesCard from "./GeneralAttributesCard";

class QARow extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            edit: false,
            final: ''
        }
    }

    componentDidMount() {
        this.setState({final: this.props.finValue ? this.props.finValue.value : ''})
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props){
            this.setState({final:  this.props.finValue ? this.props.finValue.value : '', edit: false})
        }
    }

    handleSelect(value, field){
        this.props.showAttr(field);
        !this.props.hasFinals && this.props.handleSelect(value, field)
    }

    handleEnter(e) {
        if (e.key === 'Enter') {
            this.props.changeValue(e.target.value)
        }
    }


    render() {
        return (
            <tr>
                <td className={'column attr' + (this.props.attr.required ? ' bold' : '')} onClick={() => this.props.showAttr()} title={this.props.attr.attr_id}>
                    {this.props.attr.attributeName}
                </td>
                <td className={'column ' + (this.props.hasFinals ? '' : this.props.selectedField === 'annot' ? 'active-value' : '')}
                    onClick={event => this.handleSelect(this.props.annValue, 'annot') }>
                    {this.props.annValue ? this.props.annValue : '-'}
                </td>
                <td className={'column ' + (this.props.hasFinals ? '' : this.props.selectedField === 'extr' ? 'active-value' : '')}
                    onClick={event => this.handleSelect(this.props.extrValue, 'extr')}>
                    {this.props.extrValue ? this.props.extrValue : '-'}
                </td>
                <td className={'column ' + (this.props.hasFinals ? '' : this.props.selectedField === 'llmExtraction' ? 'active-value' : '')}
                    onClick={event => this.handleSelect(this.props.llmExtractValue, 'llmExtraction')}>
                    {this.props.llmExtractValue ? this.props.llmExtractValue : '-'}
                </td>
                <td className='column'>{this.props.stanValue ? this.props.stanValue : '-'}</td>
                <td className={'column ' + (this.props.finValue && this.props.finValue.changed ? 'active-value' : '')} style={{backgroundColor: this.props.hasFinals ? "#dbfff8" : ''}} onDoubleClick={() => this.setState({edit: true})}>
                    {this.state.edit ?
                        <div>
                            <input onChange={(event) => this.setState({final: event.target.value})}
                                   onKeyDown={(e) => this.handleEnter(e)}
                                   value={this.state.final ? this.state.final : ''}/>
                                   <Image onClick={() => this.props.changeValue(this.state.final)} src='/images/check.png'/>
                                   {!this.props.hasFinals && <Image onClick={() => {
                                        let inputs = {};
                                        if (this.props.annValue){
                                            inputs = {"value": this.props.annValue, "field": "annot"}
                                        } else if (this.props.extrValue){
                                            inputs = {"value": this.props.extrValue, "field": "extr"}
                                        } else if (this.props.llmExtractValue){
                                            inputs = {"value": this.props.llmExtractValue, "field": "llmExtraction"}
                                        }
                                        this.props.refreshValue(inputs.value, inputs.field)
                                    }} src='images/broom.png' height={24} width={24}/>}
                                   <Image onClick={() => this.setState({edit:false, final: this.props.finValue ? this.props.finValue.value : '-'})} src='/images/close.png'/>
                        </div> :
                        this.props.finValue ? this.props.finValue.value : '-'}
                </td>
            </tr>
        );
    }

}

class QATable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAddAttrMode: false,
            attr: ''
        }
    }

    componentDidMount() {
        api.get(`/attributes/get`, Auth.createConfig())
            .then(json => this.setState({attributes: json.data}))
            .catch(error => console.log(error))
    }

    saveAttr(value) {
        let attr = {
            'attributeName': value ? value : this.state.attr,
            'customName': value ? value : this.state.attr
        }
        console.log(attr)
        api.post(`/attribute/add?class=${this.props.item.class_name}`, attr, Auth.createConfig())
            .then(json => {
                alert('Saved!')
                this.props.refresh(attr)
                this.setState({isAddAttrMode:false, attr: ''})
            })
            .catch(error => console.log(error))
    }

    onKey(e) {
        if (e.key === 'Enter') {
            this.saveAttr(e.target.value)
        }
    }

    handleAttrSelect(event, attr) {
        this.setState({attr: attr})
    }


    render() {

        let rows = this.props.attributes.map((v,i) => v.attributeName !== 'IGNORE LIST' ? <QARow key={i}
                                                                                                 attr={v}
                                                                                                 showAttr={(from) => this.props.showAttr(v.attributeName, from ? from : this.props.selected[v.attributeName].field)}
                                                                                                 hasFinals={this.props.hasFinals}
                                                                                                 changeValue={(value) => this.props.changeValue(value, v.attributeName)}
                                                                                                 refreshValue={(value, field) => this.props.refreshValue(value, v.attributeName, field)}
                                                                                                 handleSelect={(value, field) => this.props.handleSelect(v.attributeName, value, field)}
                                                                                                 annValue={this.props.annotated[v.attributeName]}
                                                                                                 finValue={this.props.final[v.attributeName]}
                                                                                                 stanValue={this.props.standard[v.attributeName]}
                                                                                                 selectedField={this.props.selected[v.attributeName] ? this.props.selected[v.attributeName].field : ''}
                                                                                                 extrValue={this.props.extracted[v.attributeName]}
                                                                                                 llmExtractValue={this.props.llmExtracted[v.attributeName]}/> : null);
        return (
            <Table bordered hover className='Stats-qa' style={{marginTop: '5px'}}>
                <thead>
                <tr>
                    <th className='column'/>
                    <th className='column'>Annotation Based Value</th>
                    <th className='column'>Rule Based Value</th>
                    <th className='column'>LLM Based Value</th>
                    <th className='column'>Standardized Value</th>
                    <th className='column'>Final Value</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
        );
    }

}

class DescriptionCard extends React.Component {

    boldText(value, v) {
        return value.substr(0, v.from) +
            '<span title="' + v.attr + '" class="selected" style=background-color:'+ "#ffeadb" + '>' + value.substr(v.from, v.length) + '</span>' +
            value.substr(v.from + v.length)
    }

    checkSelection(annotation, showed){

        function isOverlapping(annotation1, annotation2) {
            return !((annotation1.from + annotation1.length < annotation2.from) || (annotation2.from + annotation2.length < annotation1.from));
        }

        for (let i = 0; i < showed.length; i++) {
            if (isOverlapping(annotation, showed[i])){
                return false;
            }
        }
        return true;
    }

    createDescription() {
        if (this.props.showVal.length === 0) {
            return this.props.item.description;
        } else {
            let sorted = _.sortBy(this.props.showVal, [function (o) {
                return o.from + o.length;
            }]);
            let value = this.props.item.description;
            let showed = [];
            for (let i = sorted.length; i > 0; i--) {
                if(this.checkSelection(sorted[i - 1], showed)) {
                    value = this.boldText(value, sorted[i - 1]);
                    showed.push(sorted[i - 1])
                }
            }
            return value;
        }
    }

    render() {
        return(
            <Card border="secondary">
                <Card.Header style={{userSelect: 'none'}}
                             onSelectCapture={() => false}>
                    Item ID: {this.props.item.item} {this.props.hasFinals ? <Image src='/images/star.png'/> :  ''} <span className='d-inline align-items-center' style={{color: 'green'}}>{this.props.hasFinals ? 'This record was checked! ' : ''}</span>
                    <Button className='float-right' onClick={() => this.props.getNext()}>Get Next</Button>
                    <Button className='float-right' style={{ marginRight: '10px' }} onClick={() => this.props.getAllTokens()}>Visualize</Button>
                </Card.Header>
                <Card.Body>
                    <Card.Text >
                        <div dangerouslySetInnerHTML={{__html: this.createDescription()}}/>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }

}



class QAManager extends React.Component {

    // getAttributes(){
    //     api.get(`/attributes?class=${this.props.item.class_name}`, Auth.createConfig())
    //         .then(json => {
    //             this.setState({attributes: json.data.items})
    //         })
    //         .catch(error => console.log(error));
    // }

    getClassInfo(){
        api.get(`/classes/class?class=${this.props.item.class_name}`, Auth.createConfig())
            .then(json => {
                let attributes = json.data.attributes;
                json.data = _.omit(json.data, 'attributes');
                this.setState({attributes: attributes, classInfo: json.data})
            })
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getLLMExtracted();
        this.getClassInfo();
        this.getAnnotated();
        this.getExtracted();
        this.firstSelection();
    }

    getAnnotated() {
        api.get(`/markup?item=${this.props.item.item}`, Auth.createConfig())
            .then(json => {
                let manualMarkups = json.data[0].manualMarkups;
                let annotated = {};
                for (let i = 0; i < manualMarkups.length; i++) {
                    let attr = manualMarkups[i].attr;
                    if (attr in annotated){
                        annotated[attr] = annotated[attr] + ',' + this.props.item.description.substr(manualMarkups[i].from, manualMarkups[i].length)
                    } else {
                        annotated[attr] = this.props.item.description.substr(manualMarkups[i].from, manualMarkups[i].length)
                    }
                }
                this.setState({annotated: annotated, fullMarkups: manualMarkups})
            })
            .catch(error => console.log(error))
    }

    getFinal(standardized) {
        api.get(`/final/markup?item=${this.props.item.item}`, Auth.createConfig())
            .then(json => {
                let finalMarkups = json.data[0].final_extr;
                let final = {};
                for (let i = 0; i < finalMarkups.length; i++) {
                    let attr = finalMarkups[i].attr;
                    final[attr] = {
                        changed: false,
                        value: finalMarkups[i].value}
                }
                if (Object.values(final).length === 0){
                    for (let key in standardized){
                        if (!final[key]  || !standardized[key].changed){
                            final[key] = {
                                value: standardized[key],
                                changed: false
                            }
                        }
                    }
                    this.setState({hasFinal: false})
                } else {
                    this.setState({hasFinal: true})
                }
                this.setState({final: final})
            })
            .catch(error => console.log(error))
    }

    getExtracted(){
        api.get(`/item/extraction?item=${this.props.item.item}`, Auth.createConfig())
            .then(json => {
                let extracted = {};
                for(let i =0; i<json.data.items.length;i++){
                    let attr = json.data.items[i].attr;
                    if (attr in extracted){
                        extracted[attr] = extracted[attr] + ',' + json.data.items[i].value
                    } else {
                        extracted[attr] = json.data.items[i].value
                    }
                }
                this.setState({extracted: extracted, fullExtr:json.data.items})
            })
            .catch(error => console.log(error))
    }

    getLLMExtracted(){
        api.get(`/item/llm-extraction?item_id=${this.props.item._id}`, Auth.createConfig())
            .then(json => {
                let llmExtracted = {};
                for(let i =0; i<json.data.items.length;i++){
                    let attr = json.data.items[i].attr;
                    if (attr in llmExtracted && !llmExtracted[attr].includes(json.data.items[i].value)) {
                        llmExtracted[attr] = llmExtracted[attr] + ',' + json.data.items[i].value
                    } else {
                        llmExtracted[attr] = json.data.items[i].value
                    }
                }
                this.setState({llmExtracted: llmExtracted, fullLLMExtraction:json.data.items})
            })
            .catch(error => console.log(error))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.item.class_name !== this.props.item.class_name){
            this.getClassInfo();
        }
        if (prevProps.item !== this.props.item){
            this.getAnnotated();
            this.setState({hasFinals: false,showVal: {}});
            this.getExtracted();
            this.getLLMExtracted();
            this.firstSelection();
        }
        if (prevState.attributes !== this.state.attributes || prevState.annotated !== this.state.annotated || this.state.extracted !== prevState.extracted || this.state.llmExtracted !== prevState.llmExtracted){
            this.setState({hasFinals: false,showVal: {}});
            this.firstSelection();
        }
    }

    constructor(props){
        super(props);
        this.state = {
            attributes: [],
            annotated: {},
            hasFinal: false,
            final: {},
            showVal: [],
            selected: {},
            extracted: {},
            llmExtracted: {},
            standard: {},
            metricsVisible: false,
            changed: false,
            classInfo: {},
            activeTab: 'general_attributes',
            fullFinals: [],
        }
    }

    firstSelection(){
        let selected = {};
        for (let attr = 0; attr < this.state.attributes.length; attr++){
            
            let annotated = this.state.annotated[this.state.attributes[attr].attributeName];
            let extracted = this.state.extracted[this.state.attributes[attr].attributeName];
            let llmExtracted = this.state.llmExtracted[this.state.attributes[attr].attributeName];
            let value = '';
            let field = '';
            
            if(annotated){
                value = annotated
                field = 'annot'
            } else if (extracted){
                value = extracted
                field = 'extr'
            } else if (llmExtracted){
                value = llmExtracted
                field = 'llmExtraction'
            }

            selected[this.state.attributes[attr].attributeName] = {
                value: value,
                field: field
            }
        }
        this.setState({selected: selected});
        this.getStandard(selected);
    }

    refresh(attr) {
        let attributes = this.state.attributes;
        attributes.push(attr)
        this.setState({attributes: attributes})
    }

    getStandard(standard){
        api.post(`/standard`,standard, Auth.createConfig())
            .then(json => {
                let standarded = {};
                for(let attr in json.data.items){
                    if (attr in standarded){
                        standarded[attr] = standarded[attr] + ',' + json.data.items[attr].std
                    } else {
                        standarded[attr] = json.data.items[attr].std
                    }
                }
                this.setState({standard: standarded});
                this.getFinal(standarded)
            })
            .catch(error => console.log(error))
    }

    handleSubmit() {
        let markups = [];
        for (let attr in this.state.final){
            if(this.state.final[attr].value) {
                markups.push({
                    attr: attr,
                    value: this.state.final[attr].value
                })
            }
        }
        let result = {
            id: this.props.item.item,
            final: markups
        };
        api.post(`/save/final/markup`, result, Auth.createConfig())
            .then(json => {
                // alert('This record was saved');
                this.getFinal()
                this.setState({changed: false})
            })
            .catch(error => console.log(error))
    }

    handleUndoSubmit() {
        api.delete(`/delete/final/markup?item_id=${this.props.item.item}`, Auth.createConfig())
            .then(json => {
                this.componentDidMount()
            })
            .catch(error => console.log(error))
    }

    handleSelection(attr, value, field){
        if(value) {
            let selected = this.state.selected;
            if (attr in this.state.final && !this.state.final[attr].changed) {
                selected[attr] = {
                    value: value,
                    field: field
                };
                this.setState({selected: selected});
                this.getStandard(selected)
            }
        }
    }

    getByAttr(array, attr){
        let result = [];
        for (let i = 0; i < array.length; i++) {
            if(array[i].attr === attr){
                result.push(array[i]);
            }
        }
        return result;
    }

    getFinalValues() {
        let values = [];
        for (const key in this.state.selected) {
            if (this.state.selected[key].field === 'final') {
                values.push(this.getByAttr(this.state.fullFinals, key))
            }else if (this.state.selected[key].field === 'annot'){
                values.push(this.getByAttr(this.state.fullMarkups, key))
            } else if (this.state.selected[key].field === 'extr'){
                values.push(this.getByAttr(this.state.fullExtr, key))
            } else if (this.state.selected[key].field === 'llmExtraction'){
                values.push(this.getByAttr(this.state.fullLLMExtraction, key))
            }
        }
        return values
    }

    showAllAttr() {
        let showValues = this.getFinalValues();
        let valuesToShow = [];
        showValues.forEach(sublist => {
            valuesToShow = valuesToShow.concat(sublist);
        });
        this.setState({showVal: valuesToShow});
    }

    showAttr(attr, field) {
        if (field === 'final') {
            this.setState({showVal: this.getByAttr(this.state.fullFinals, attr)})
        }else if (field === 'annot'){
            this.setState({showVal: this.getByAttr(this.state.fullMarkups, attr)})
        } else if (field === 'extr'){
            this.setState({showVal: this.getByAttr(this.state.fullExtr, attr)})
        } else if (field === 'llmExtraction'){
            this.setState({showVal: this.getByAttr(this.state.fullLLMExtraction, attr)})
        }
    }

    changeValue(value, attr) {
        let fullFinals = this.state.fullFinals
        let final = this.state.final;
        let selected = this.state.selected;
        
        final[attr] = {
            changed:true,
            value: value
        };
        
        selected[attr] = {
            value: value,
            field: "final"
        };
        

        this.state.fullFinals.map(item => {
                if (attr === item.attr) {
                    fullFinals.splice(_.indexOf(fullFinals, item), 1);
                }
            }
        )
        let result = {
            description: this.props.item.description,
            attributes: { [attr] : value }
        };
        api.post(`/matching-attribute-tokens`, result, Auth.createConfig())
            .then(json => {
                json.data.items.forEach(item => {
                    fullFinals.push(item);
                })
                this.setState({selected: selected, final: final, changed: true, fullFinals: fullFinals});
            })
            .catch(error => console.log(error))
    }

    refreshValue(value, attr, field) {
        let final = this.state.final;
        let selected = this.state.selected;
        final[attr] = {
            changed:false,
            value: value
        };
        selected[attr] = {
            value: value,
            field: field
        };
        this.setState({selected: selected, final: final, changed: false});
    }

    render() {
        return(
            <div>
                <DescriptionCard item={this.props.item}
                                 showVal={this.state.showVal}
                                 hasFinals={this.state.hasFinal}
                                 getNext={() => {
                                    this.props.getNext();
                                    this.setState({metricsVisible: false})
                                 }}
                                 getAllTokens={() => this.showAllAttr()}
                />
                <Tabs id="controlled-tab-example" activeKey={this.state.activeTab} onSelect={(k) => this.setState({activeTab: k})} style={{marginTop: '10px'}} variant="pills">
                    <Tab eventKey="general_attributes" title="General Attributes">
                        <GeneralAttributesCard item={this.props.item} classInfo={this.state.classInfo}/>
                    </Tab>
                    <Tab eventKey="metrics" title="Metrics">
                        <MetricsCard item={this.props.item} finalValues={this.getFinalValues()} visible={this.state.metricsVisible} setVisible={(x) => this.setState({metricsVisible: x})}/>
                    </Tab>
                </Tabs>
                <QATable attributes={this.state.attributes}
                         handleSelect={(attr, value, field) => this.handleSelection(attr, value, field)}
                         selected={this.state.selected}
                         final={this.state.final}
                         item={this.props.item}
                         refresh={(attr) => this.refresh(attr)}
                         changeValue={(value, attr) => this.changeValue(value, attr)}
                         refreshValue={(value, attr, field) => this.refreshValue(value,attr, field)}
                         showAttr={(attr, field) => this.showAttr(attr, field)}
                         standard={this.state.standard}
                         annotated={this.state.annotated}
                         hasFinals={this.state.hasFinal}
                         extracted={this.state.extracted}
                         llmExtracted={this.state.llmExtracted}
                />
                {(!this.state.hasFinal || (this.state.hasFinal && this.state.changed)) && (<Button className='float-right' onClick={() => this.handleSubmit()}>Submit</Button>)}
                {this.state.hasFinal && !this.state.changed && (<Button className='float-right' style={{opacity: '0.5'}} onClick={() => {}}>Submit</Button>)}
                {this.state.hasFinal && (<Button className='float-right' style={{ marginRight: '10px' }} onClick={() => this.handleUndoSubmit()}>Undo Submit</Button>)}
            </div>
        );
    }

}

export default QAManager;
