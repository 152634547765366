import React, { useState } from "react";
import {Card} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import * as Auth from "../AuthService";
import * as _ from "lodash";
import './QAManager.css';
import api from "../api";

function MetricsCard(props) {
    const [tokenCompleteness, setTokenCompleteness] = useState(0);
    return (
        <Card border="secondary">
                <Card.Header style={{userSelect: 'none'}}
                                onSelectCapture={() => false}>
                    Metrics
                    <Button className='float-right' onClick={() => getTokenCompleteness(props.item._id, props.finalValues, setTokenCompleteness, props.setVisible)}>Get Metrics</Button>
                </Card.Header>
                {props.visible ? <Card.Body>
                    <Card.Text >
                        Token completeness: {_.round(tokenCompleteness, 2)}
                    </Card.Text>
                </Card.Body>
                : <></>}
        </Card>
    )
}

function getTokenCompleteness(item_id, finalValues, setTokenCompleteness, setVisible) {
    let valuesToCheck = {
        "final_values": finalValues
    }
    api.post(`/metrics?item_id=${item_id}`, valuesToCheck, Auth.createConfig())
            .then(json => {
                setVisible(true)
                setTokenCompleteness(json.data.items.token_completeness)
            })
            .catch(error => console.log(error))
}

export default MetricsCard
