import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import * as _ from "lodash";
import './QAManager.css';

function handleGoogleSearch(query) {
    return `https://www.google.com/search?q=${encodeURIComponent(query)}`;
}

function GeneralAttributesCard(props) {
    const partNumber = props.item.part_number;
    const manufName = props.item.manuf_name;
    const classId = props.classInfo.class_id;

    return (
        <Card border="secondary">
            <Card.Header style={{userSelect: 'none'}}
                        onSelectCapture={() => false}>
                General Attributes
            </Card.Header>  
            <Card.Body>
                <Row>
                    <Col key={1} md={4}>
                        <Card.Text>
                            PART NUMBER: <b>
                                {partNumber === "" || partNumber === "N/A" || partNumber === "NA" ? "N/A" : (
                                    <a href={handleGoogleSearch(partNumber)} target="_blank" rel="noopener noreferrer">
                                        {partNumber}
                                    </a>
                                )}
                            </b>
                        </Card.Text>
                    </Col>
                    <Col key={2} md={4}>
                        <Card.Text>
                            MANUFACTURE NAME: <b>
                                {manufName === "" || manufName === "N/A" || manufName === "NA" ? "N/A" : (
                                    <a href={handleGoogleSearch(manufName)} target="_blank" rel="noopener noreferrer">
                                        {manufName}
                                    </a>
                                )}
                            </b>
                        </Card.Text>
                    </Col>
                    <Col key={3} md={4}>
                        <Card.Text>
                            Class ID: <b>
                                {classId === "" || classId === "N/A" || classId === "NA" ? "N/A" : classId}
                            </b>
                        </Card.Text>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default GeneralAttributesCard
