import "./Menu.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "../index.css";

import React, { Component } from "react";
import { Layout, Row, Space, Tooltip, Menu as DropdownMenu } from "antd";

import { Image } from "react-bootstrap";

import { properties } from "../properties.js";
import api from "../api";
import {
  About,
  ContactUsLink,
  ContactUsModal,
  CurrentUserAvatar,
  GuideLink,
  HomeLink,
  LogOut,
  OtherResources,
} from "@digatex/digatex-ui-lib";

export function Link({ href, children, style }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: properties.kbrColors.pantone301Blue, ...style }}
    >
      {children}
    </a>
  );
}
export const menuItems = (
  <DropdownMenu>
    <DropdownMenu.Item>
      <a target="_blank" rel="noopener noreferrer" href={properties.ihLink}>
        <Tooltip placement="right" title="Inventory Hub">
          <Image style={{ height: 32 }} src={`/images/ih.png`} />{" "}
        </Tooltip>
      </a>
    </DropdownMenu.Item>
    <DropdownMenu.Item>
      <a target="_blank" rel="noopener noreferrer" href={properties.dmLink}>
        <Tooltip placement="right" title="Data Mapper">
          <Image style={{ height: 32 }} src={`/images/dm.png`} />
        </Tooltip>
      </a>
    </DropdownMenu.Item>
    <DropdownMenu.Item>
      <a target="_blank" rel="noopener noreferrer" href={properties.khLink}>
        <Tooltip placement="right" title="Knowledge Hub">
          <Image style={{ height: 32 }} src={`/images/kh.png`} />
        </Tooltip>
      </a>
    </DropdownMenu.Item>
    <DropdownMenu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={properties.analyticsLink}
      >
        <Tooltip placement="right" title="Workbench">
          <Image style={{ height: 32 }} src={`/images/analytics.png`} />
        </Tooltip>
      </a>
    </DropdownMenu.Item>
    <DropdownMenu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={properties.assetHubLink}
      >
        <Tooltip placement="right" title="Equipment Hub">
          <Image style={{ height: 32 }} src={`/images/asset-hub.svg`} />
        </Tooltip>
      </a>
    </DropdownMenu.Item>
  </DropdownMenu>
);

function sendEmail(formData) {
  return api.post(`/email/contact`, formData);
}

class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactForm: false,
      triedToSubmit: false,
      submitInProgress: false,
    };

    this.formRef = React.createRef();
  }

  render() {
    return (
      <div>
        <Layout.Header
          style={{
            width: "100%",
            padding: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "fixed",
          }}
        >
          <HomeLink />
          <div style={{ marginLeft: "auto" }}>
            <Row>
              <Space size={"middle"}>
                <About />
                <OtherResources />
                <GuideLink
                  url={
                    process.env.PUBLIC_URL +
                    "/files/1000-100-DAT-00007 11 Quick reference guide - DIGATEX Engineering Hub.pdf"
                  }
                />
                <ContactUsLink
                  show={() => this.setState({ showContactForm: true })}
                />
                <LogOut />
                <CurrentUserAvatar />
              </Space>
            </Row>
          </div>
        </Layout.Header>
        <ContactUsModal
          show={this.state.showContactForm}
          sendEmail={sendEmail}
          close={() => this.setState({ showContactForm: false })}
        />
      </div>
    );
  }
}

export default NavMenu;
