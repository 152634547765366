import React, {useState} from 'react';
import "../index.less";
import * as _ from 'lodash';
import * as Auth from '../AuthService';
import {properties} from "../properties";

import {Button} from "react-bootstrap";
import api from "../api";
import FileDownload from "js-file-download";
import {Dropdown, Menu as DropdownMenu, Spin, Table, Tag} from "antd";
import "./SourceTable.css";

function EntityLinksDropdown({material, tagNumber, type, children, batch}) {
    const items = <DropdownMenu>
        <DropdownMenu.Item>
            <a target="_blank" rel="noopener noreferrer" href={`${properties.analyticsLink}/projects/6295c7173ae8947d89af2c73/min-max-material-analysis?material=${material}`}>
                Workbench
            </a>
        </DropdownMenu.Item>
        <DropdownMenu.Item>
            <a target="_blank" rel="noopener noreferrer" href={`${properties.ihLink}/data?operator=Client&material=${material}`}>
                Inventory
            </a>
        </DropdownMenu.Item>
        <DropdownMenu.Item>
            <a target="_blank" rel="noopener noreferrer" href={`${properties.khLink}/user_view/search?q=${tagNumber || material}`}>
                Knowledge
            </a>
        </DropdownMenu.Item>
        <DropdownMenu.Item>
            <a target="_blank" rel="noopener noreferrer" href={`${properties.dmLink}/dm?search=${material}&batch=${batch}`}>
                Engineering
            </a>
        </DropdownMenu.Item>
        <DropdownMenu.Item>
            <a target="_blank" rel="noopener noreferrer" href={`${properties.assetHubLink}/entity_links?type=${type}&item=${material}`}>
                Equipment
            </a>
        </DropdownMenu.Item>
    </DropdownMenu>

    return (
        <Dropdown overlay={items}>
            {children}
        </Dropdown>
    );
}



export default function SourceTable(props){
    
    const [loading, setLoading] = useState(false);

    function onChange(pagination, _filters, sorter){
        if (_filters){
            if (_filters.tags && _filters.tags.includes("verified") && _filters.tags.includes("unverified")){
                props.setSource([]);
                props.setTagsFilter(_filters.tags);
                return;
            }
            props.onChangeTable(pagination.current, _filters.tags);
        }
    }

    const exportFullExtraction = () => {
        setLoading(true);
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        api.get(`/batch/full/export?batch=${props.batch}&class=${props.class}`, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename, filename);
            })
            .catch(
                error => {
                    console.log(error);
                    alert("Sorry, something went wrong.");
                }
            )  
            .finally(() => setLoading(false))
    }




    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Class',
            dataIndex: 'class_name',
            key: 'class',
        },
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            render: (item) => (
                <p style={{color: "blue", "text-decoration": "underline"}}>{item}</p>
              ),
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'description',
        },
        {
            title: 'Tags',
            key: 'tags',
            dataIndex: 'tags',
            filters: [
                {
                    text: 'Annotated',
                    value: 'annotated',
                },
                {
                    text: 'Verified',
                    value: 'verified',
                },
                {
                    text: 'Unverified',
                    value: 'unverified',
                },
            ],
            filteredValue: props.tagsFilter,
            render: tags => (
                <>
                    {tags.map(tag => {
                        let color = null;
                        switch(tag){
                            case 'annotated':
                                color = 'blue';
                                break;
                            case 'verified':
                                color = 'green';
                                break;
                            default:
                                color = 'grey';
                                break;
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'actions',
            render: actions => (
                <>
                    {actions.map(action => {
                        return (
                            <p style={{color: "blue", "text-decoration": "underline"}}>{action.component}</p>
                        );
                    })}
                </>
            ),
        },
    ];

    let rows = [];
    if(props.source.length > 0 && props.markups.length > 0 && props.finals.length > 0) {
        props.source.map((v, i) => {
            let item = {};
            item.index = i + 1;
            item.class_name = v.class_name;
            item.item = <EntityLinksDropdown material={v.item} batch={props.batch} type={'material'} children={<div>{v.item}</div>} />
            item.description = v.description;

            item.tags = [];
            if (props.markups && props.markups[i] > 0){
                item.tags.push("annotated");
            }
            if (props.finals && props.finals[i] > 0){
                item.tags.push("verified");
            }else if (props.finals && props.finals[i] == 0){
                item.tags.push("unverified");
            }

            item.actions = [];
            item.actions.push({component: <a onClick={() => props.handleAnnotation(v)}>Annotate</a>});
            item.actions.push({component: <a onClick={() => props.openQA(v)}>QA</a>});
            rows.push(item);
        });
    }

    const exportButton = <Button size={"sm"} onClick={exportFullExtraction} disabled={loading}>
                            {loading ? 'Getting Extractions... ' : 'Attribute Extraction'} {loading && <Spin/>}
                        </Button>
    return (
        <div>
            <div style={{marginTop: 5}}>{props.class ?
                <div>Results for class: <b>{props.class}</b> (batch <b>{props.batch}</b>){exportButton}</div> :
                <div>Results for batch: <b>{props.batch}</b> {exportButton} </div>}</div>
            <div>Total number of items in the batch: <b>{props.batchSize.toLocaleString()}</b> (Approved: <b>{props.approved.toLocaleString()}</b>)</div>
            <Table  columns={columns} 
                    dataSource={rows} 
                    onChange={onChange} 
                    pagination={{
                        position: 'bottomCenter',
                        showSizeChanger: false,
                        current: props.current,
                        total: props.total,
                        pageSize: properties.previewRowsPerPage,
                    }}
                    />
        </div>
    );
}