import { loadUIConfig } from "@digatex/digatex-ui-lib"

export const properties = {
    apiUrl: process.env.REACT_APP_HOST,
    previewRowsPerPage: 50,
    version: process.env.REACT_APP_DM_VERSION,
    kbrColors: {
        pantone301Blue: "#004987",
        lightBlue: "#A2C7E2",
        yellow: "#ffda00",
        dark: "#00205C",
        green: "#00783F",
        black:"#231f20",
        vividCeruleanBlue: "#007fc5",
        acajouMarron: "#4c212a"

    },
    colors: [
        "#fac8d6",
        "#ffdbdd",
        "#fac8c8",
        "#ffdbeb",
        "#ffdbf5",
        "#fddbff",
        "#f3defa",
        "#f3dbff",
        "#eadbff",
        "#e2dbff",
        "#dbe4ff",
        "#dbf1ff",
        "#dbfff8",
        "#dbffea",
        "#dbffdb",
        "#ebffdb",
        "#f3ffdb",
        "#feffdb",
        "#fff8db",
        "#ffeedb",
        "#ffeadb",
        "#ffe2db",
        "#ffdcdb",
        "#ffdbdb",
        "#dbe4ff",
        "#dbf1ff",
        "#dbfff8",
        "#dbffea",
        "#dbffdb",
        "#ebffdb",
        "#f3ffdb"
    ],
    ihLink: process.env.REACT_APP_IH_LINK,
    dmLink: process.env.REACT_APP_DM_LINK,
    khLink: process.env.REACT_APP_KH_LINK,
    clLink: process.env.REACT_APP_CL_LINK,
    analyticsLink: process.env.REACT_APP_WB_LINK,
    assetHubLink: process.env.REACT_APP_EH_LINK,
    clPublicLink: process.env.REACT_APP_CL_LINK_PUBLIC,
};

export const SHOW_DM_IN_MENU = process.env.REACT_APP_SHOW_DM_IN_MENU === undefined ? true : process.env.REACT_APP_SHOW_DM_IN_MENU === 'true'
export const SHOW_SETTINGS = process.env.REACT_APP_SHOW_SETTINGS === undefined ? true : process.env.REACT_APP_SHOW_SETTINGS === 'true'
export const SHOW_REGISTER = process.env.REACT_APP_SHOW_REGISTER === undefined ? true : process.env.REACT_APP_SHOW_REGISTER === 'true'
export const SHOW_REPORTS = process.env.REACT_APP_SHOW_REPORTS === undefined ? true : process.env.REACT_APP_SHOW_REPORTS === 'true'
export const SHOW_SOURCE_MATRIX = process.env.REACT_APP_SHOW_SOURCE_MATRIX === undefined ? true : process.env.REACT_APP_SHOW_SOURCE_MATRIX === 'true'

export const KEYCLOAK_AUTH_SERVER_URL = process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL

export const SHOW_FIRST_IN_REGISTERS = process.env.REACT_APP_SHOW_FIRST_IN_REGISTERS
export const OWNER = process.env.REACT_APP_OWNER

export const UI_CONFIG = loadUIConfig()
UI_CONFIG.header.logo.title = process.env.REACT_APP_PROJECT_TITLE
